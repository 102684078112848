


$FF: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif; 
$default_font-size: 16px;


body {
  font-size: $default_font-size;
  font-family: $FF;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

a{
  text-decoration: none;
  color: $link-color;
  &:hover{
    color: $link-hover-color;
  }
}
