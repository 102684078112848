/////////////////////////
///// START Sticky Footer

html,
body{
  height: 100%;
}

body{
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

header,
footer {
  flex: none; /* 2 */
}

main {
  flex: 1 0 auto; /* 2 */
  padding: var(--space) var(--space) 0;
  width: 100%;
}

main::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: var(--space);
  height: 0px;
  visibility: hidden;
}

///// END Sticky Footer
//////////////////////////////


header{
  // background: pink;
}

main{
  h1{
    text-align: center;
    width: 90%;
    position: absolute;
    color: white;
    font-weight: normal;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    font-size: 2.5em;
    @media(min-width:768px){
      font-size: 4em;
      top: 20%;

    }
    @media(min-width:1024px){
      font-size: 6.5em;
      top: 20%;

    }
  }

  .contact{
    line-height: 1.4em;
    position: absolute;
    z-index: 1;
    right: 20px;
    bottom: 20px;
    color: white;
    width: auto;
    display: inline-block;
    a{
      color: white;
    }
    @media(min-width:768px){
      font-size: 1.1em;
      right: 30px;
      bottom: 30px;
    }
  }
}

footer{
  // background: $grey;
}

.wrapper{
  width: 100%;
  max-width: 720px;
  margin: auto;

  &--large{
    max-width: 960px;
  }
}

.bg{
  position: absolute;
  width: 100vw;
  height: 100vh;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 85% 50%;
  }

  &.compat-object-fit {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    img{
      display: none;
    }
  }
}
